import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const OpportunitiesScreen = () => {
  const [approvedOpportunities, setApprovedOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    opportunity: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);

  useEffect(() => {
    const fetchApprovedOpportunities = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedOpportunities")
          .orderBy("createdAt", "desc")
          .get();
        const opportunities = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = opportunities.map((opportunity) => opportunity.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedOpportunities(opportunities);
        setFilteredOpportunities(opportunities);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved opportunities: ", error);
        setLoading(false);
      }
    };

    fetchApprovedOpportunities();
  }, []);

  useEffect(() => {
    setFilteredOpportunities(
      approvedOpportunities.filter((opportunity) => {
        const userDetail = userDetails[opportunity.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          opportunity.companyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          opportunity.mobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          opportunity.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          opportunity.role?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          opportunity.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          opportunity.content?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [searchTerm, approvedOpportunities, userDetails]);

  const handleDelete = (opportunity) => {
    setModalState({ show: true, opportunity });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedOpportunities")
        .doc(modalState.opportunity.id)
        .delete();
      setApprovedOpportunities((prev) =>
        prev.filter((item) => item.id !== modalState.opportunity.id)
      );
      setFilteredOpportunities((prev) =>
        prev.filter((item) => item.id !== modalState.opportunity.id)
      );
      setModalState({ show: false, opportunity: null });
    } catch (error) {
      console.error("Error deleting opportunity: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, opportunity: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Opportunities: {filteredOpportunities.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Opportunities..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredOpportunities.map((opportunity) => {
          const userDetail = userDetails[opportunity.userId] || {};
          return (
            <div key={opportunity.id} className="contentItemOpportunities">
              <div className="user-info-container">
                <img
                  src={userDetail.profileImage || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Firm:</p>
                <p className="detail">{opportunity.companyName}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Mobile:</p>
                <p className="detail">{opportunity.mobile}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Email:</p>
                <p className="detail">{opportunity.email}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Role:</p>
                <p className="detail">{opportunity.role}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Location:</p>
                <p className="detail">{opportunity.location}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Joining:</p>
                <p className="detail">{opportunity.joining}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Shift:</p>
                <p className="detail">{opportunity.shift}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Working Duration:</p>
                <p className="detail">
                  {new Date(
                    opportunity.startDate?.seconds * 1000
                  ).toDateString()}{" "}
                  to{" "}
                  {opportunity.endDate
                    ? new Date(
                        opportunity.endDate.seconds * 1000
                      ).toDateString()
                    : "N/A"}
                </p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Working Time:</p>
                <p className="detail">
                  {new Date(
                    opportunity.startTime?.seconds * 1000
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}{" "}
                  -{" "}
                  {new Date(
                    opportunity.endTime?.seconds * 1000
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Pay Type:</p>
                <p className="detail">{opportunity.payType}</p>
              </div>
              {(opportunity.payType === "Stipend" ||
                opportunity.payType === "Salary") && (
                <div className="detail-container">
                  <p className="name label detail">Pay Amount:</p>
                  <p className="detail">{opportunity.payAmount}</p>
                </div>
              )}
              <div className="detail-container">
                <p className="name label detail">Preferred Gender:</p>
                <p className="detail">{opportunity.preferredGender}</p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Who Can Apply: </strong>
                  {opportunity.whoCanApply.join(", ")}
                </p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Perks: </strong>
                  {opportunity.perks.join(", ")}
                </p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Description: </strong>
                  {opportunity.content}
                </p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Number of Openings:</p>
                <p className="detail">{opportunity.openings}</p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={() => handleDelete(opportunity)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this opportunity?`}
        />
      )}
    </>
  );
};

export default OpportunitiesScreen;
