import React, { useState } from "react";
import "./Payments.css"; // For consistent styling
import NewsScreen from "./NewsScreen";
import BlogsScreen from "./BlogScreen";
import VideosScreen from "./VideosScreen";
import NewsUpload from "./NewsUpload";
import TransactionHistory from "./TransactionHistory";
import DeletionRequests from "./DeletionRequests";
import WithdrawalRequests from "./WithdrawalRequests";

const TransactionsAndRequests = () => {
  const [activeTab, setActiveTab] = useState("Transactions");

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "Transactions":
        return <TransactionHistory />;
      case "Deletion Requests":
        return <DeletionRequests />;
      case "Withdrawal Requests":
        return <WithdrawalRequests />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="sub-tabs">
        <div
          className={`sub-tab ${activeTab === "Transactions" ? "active" : ""}`}
          onClick={() => setActiveTab("Transactions")}
        >
          Transactions
        </div>
        <div
          className={`sub-tab ${activeTab === "Deletion Requests" ? "active" : ""}`}
          onClick={() => setActiveTab("Deletion Requests")}
        >
          Deletion Requests
        </div>
        <div
          className={`sub-tab ${activeTab === "Withdrawal Requests" ? "active" : ""}`}
          onClick={() => setActiveTab("Withdrawal Requests")}
        >
          Withdrawal Requests
        </div>
      </div>

      <div className="sub-tab-content">{renderActiveTabContent()}</div>
    </div>
  );
};

export default TransactionsAndRequests;
