import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./ContentPage.css";
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const DetailsModal = ({ show, onClose, lawyer, userDetail, onSave }) => {
  const [editLawyer, setEditLawyer] = useState(lawyer);

  useEffect(() => {
    setEditLawyer(lawyer);
  }, [lawyer]);

  const handleFieldChange = (field, value) => {
    setEditLawyer((prev) => ({ ...prev, [field]: value }));
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-payment">
      <div className="modal-content-payment">
        <h2>Edit Lawyer Details</h2>
        <div className="detail-container">
          <p className="label">Name:</p>
          <input
            className="detail"
            value={editLawyer.fullName}
            onChange={(e) => handleFieldChange("fullName", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">City:</p>
          <input
            className="detail"
            value={editLawyer.residentialAddress.city}
            onChange={(e) =>
              handleFieldChange("residentialAddress", {
                ...editLawyer.residentialAddress,
                city: e.target.value,
              })
            }
          />
        </div>
        <div className="detail-container">
          <p className="label">Enrollment ID:</p>
          <input
            className="detail"
            value={editLawyer.enrollmentId}
            onChange={(e) => handleFieldChange("enrollmentId", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Experience:</p>
          <input
            className="detail"
            value={editLawyer.experience}
            onChange={(e) => handleFieldChange("experience", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Service Fee:</p>
          <input
            className="detail"
            value={editLawyer.serviceFee}
            onChange={(e) => handleFieldChange("serviceFee", e.target.value)}
          />
        </div>
        <div className="modal-actions">
        <button onClick={() => onSave(editLawyer)} className="modal-button">
          Save
        </button>
        <button onClick={onClose} className="modal-button">
          Cancel
        </button>
        </div>
      </div>
    </div>
  );
};


const LawyersScreen = () => {
  const [approvedLawyers, setApprovedLawyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    lawyer: null,
  });
  const [detailsModalState, setDetailsModalState] = useState({
    show: false,
    lawyer: null,
    isEditMode: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLawyers, setFilteredLawyers] = useState([]);

  useEffect(() => {
    const fetchApprovedLawyers = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedLawyers")
          .orderBy("createdAt", "desc")
          .get();
        const lawyers = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch user details for each lawyer
        const userIds = lawyers.map((lawyer) => lawyer.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedLawyers(lawyers);
        setFilteredLawyers(lawyers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved lawyers: ", error);
        setLoading(false);
      }
    };

    fetchApprovedLawyers();
  }, []);

  useEffect(() => {
    setFilteredLawyers(
      approvedLawyers.filter((lawyer) => {
        const userDetail = userDetails[lawyer.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawyer.residentialAddress.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawyer.enrollmentId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawyer.experience?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawyer.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawyer.practiceAreas.some((area) =>
            area.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
    );
  }, [searchTerm, approvedLawyers, userDetails]);

  const handleDelete = (lawyer) => {
    setModalState({ show: true, lawyer });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedLawyers")
        .doc(modalState.lawyer.id)
        .delete();
      setApprovedLawyers((prev) =>
        prev.filter((item) => item.id !== modalState.lawyer.id)
      );
      setFilteredLawyers((prev) =>
        prev.filter((item) => item.id !== modalState.lawyer.id)
      );
      setModalState({ show: false, lawyer: null });
    } catch (error) {
      console.error("Error deleting lawyer: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, lawyer: null });
  };

  const handleItemClick = (lawyer) => {
    setDetailsModalState({ show: true, lawyer });
  };

  const handleEdit = (lawyer) => {
    setDetailsModalState({ show: true, lawyer, isEditMode: true });
  };

  const handleSave = async (updatedLawyer) => {
    try {
      await firebase.firestore().collection("approvedLawyers").doc(updatedLawyer.id).update(updatedLawyer);
      setApprovedLawyers((prev) =>
        prev.map((lawyer) => (lawyer.id === updatedLawyer.id ? updatedLawyer : lawyer))
      );
      setDetailsModalState({ show: false, lawyer: null, isEditMode: false });
      window.alert("Lawyer details updated successfully.");
    } catch (error) {
      console.error("Error updating lawyer details: ", error);
      window.alert("There was an error updating the details. Please try again.");
    }
  };

  const closeDetailsModal = () => {
    setDetailsModalState({ show: false, lawyer: null, isEditMode: false });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Lawyers: {filteredLawyers.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Lawyers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredLawyers.map((lawyer) => {
          const userDetail = userDetails[lawyer.userId] || {};
          return (
            <div
              key={lawyer.id}
              className="contentItem"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={lawyer.profilePicture || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>

              {/* <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div> */}
              <div className="detail-container">
                <h3 className="name label detail">Joined At:</h3>
                <h3 className="detail">
                  {lawyer.createdAt
                    ? new Date(lawyer.createdAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : lawyer.updatedAt
                    ? new Date(lawyer.updatedAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : "Timestamp not available"}
                </h3>
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{lawyer.fullName}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">City:</p>
                <p className="detail">{lawyer.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Enrollment ID:</p>
                <p className="detail">{lawyer.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Experience:</p>
                <p className="detail">{lawyer.experience}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Service Fee:</p>
                <p className="detail">
                  ₹ {lawyer.serviceFee} 
                </p>
              </div>
              <div className="buttonContainer">
              <button
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up
                    handleEdit(lawyer); // Handle edit
                  }}
                  style={{marginTop: 10}}
                >
                  Edit
                </button>
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent
                    handleDelete(lawyer);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this lawyer?`}
        />
      )}
      {detailsModalState.show && (
        <DetailsModal
        show={detailsModalState.show}
        onClose={closeDetailsModal}
        lawyer={detailsModalState.lawyer}
        userDetail={userDetails[detailsModalState.lawyer.userId] || {}}
        onSave={handleSave}
      />
      )}
    </>
  );
};

export default LawyersScreen;
