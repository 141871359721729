import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const DetailsModal = ({ show, onClose, lawFirm, userDetail, onSave }) => {
  const [editLawFirm, setEditLawFirm] = useState(lawFirm);

  useEffect(() => {
    setEditLawFirm(lawFirm);
  }, [lawFirm]);

  const handleFieldChange = (field, value) => {
    setEditLawFirm((prev) => ({ ...prev, [field]: value }));
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-payment">
      <div className="modal-content-payment">
        <h2>Edit Law Firm Details</h2>
        <div className="detail-container">
          <p className="label">Name:</p>
          <input
            className="detail"
            value={editLawFirm.firmName}
            onChange={(e) => handleFieldChange("firmName", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">City:</p>
          <input
            className="detail"
            value={editLawFirm.residentialAddress.city}
            onChange={(e) =>
              handleFieldChange("residentialAddress", {
                ...editLawFirm.residentialAddress,
                city: e.target.value,
              })
            }
          />
        </div>
        <div className="detail-container">
          <p className="label">Enrollment ID:</p>
          <input
            className="detail"
            value={editLawFirm.enrollmentId}
            onChange={(e) => handleFieldChange("enrollmentId", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Experience:</p>
          <input
            className="detail"
            value={editLawFirm.totalExperience}
            onChange={(e) => handleFieldChange("totalExperience", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Service Fee:</p>
          <input
            className="detail"
            value={editLawFirm.serviceFee}
            onChange={(e) => handleFieldChange("serviceFee", e.target.value)}
          />
        </div>
        <div className="modal-actions">
          <button onClick={() => onSave(editLawFirm)} className="modal-button">
            Save
          </button>
          <button onClick={onClose} className="modal-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};


const LawFirmsScreen = () => {
  const [approvedLawFirms, setApprovedLawFirms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    lawFirm: null,
  });
  const [detailsModalState, setDetailsModalState] = useState({
    show: false,
    lawFirm: null,
    isEditMode: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLawFirms, setFilteredLawFirms] = useState([]);

  useEffect(() => {
    const fetchApprovedLawFirms = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedLawFirms")
          .orderBy("createdAt", "desc")
          .get();
        const lawFirms = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = lawFirms.map((lawFirm) => lawFirm.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedLawFirms(lawFirms);
        setFilteredLawFirms(lawFirms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved law firms: ", error);
        setLoading(false);
      }
    };

    fetchApprovedLawFirms();
  }, []);

  useEffect(() => {
    setFilteredLawFirms(
      approvedLawFirms.filter((lawFirm) => {
        const userDetail = userDetails[lawFirm.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawFirm.residentialAddress.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawFirm.enrollmentId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawFirm.totalExperience?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawFirm.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lawFirm.practiceAreas.some((area) =>
            area.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
    );
  }, [searchTerm, approvedLawFirms, userDetails]);

  const handleDelete = (lawFirm) => {
    setModalState({ show: true, lawFirm });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedLawFirms")
        .doc(modalState.lawFirm.id)
        .delete();
      setApprovedLawFirms((prev) =>
        prev.filter((item) => item.id !== modalState.lawFirm.id)
      );
      setFilteredLawFirms((prev) =>
        prev.filter((item) => item.id !== modalState.lawFirm.id)
      );
      setModalState({ show: false, lawFirm: null });
    } catch (error) {
      console.error("Error deleting law firm: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, lawFirm: null });
  };

  const handleItemClick = (lawFirm) => {
    setDetailsModalState({ show: true, lawFirm });
  };

  const handleEdit = (lawFirm) => {
    setDetailsModalState({ show: true, lawFirm, isEditMode: true });
  };

  const handleSave = async (updatedLawFirm) => {
    try {
      await firebase.firestore().collection("approvedLawFirms").doc(updatedLawFirm.id).update(updatedLawFirm);
      setApprovedLawFirms((prev) =>
        prev.map((firm) => (firm.id === updatedLawFirm.id ? updatedLawFirm : firm))
      );
      setDetailsModalState({ show: false, lawFirm: null, isEditMode: false });
      window.alert("Law Firm details updated successfully.");
    } catch (error) {
      console.error("Error updating law firm details: ", error);
      window.alert("There was an error updating the details. Please try again.");
    }
  };

  const closeDetailsModal = () => {
    setDetailsModalState({ show: false, lawFirm: null, isEditMode: false });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Law Firms: {filteredLawFirms.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Law Firms..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredLawFirms.map((lawFirm) => {
          const userDetail = userDetails[lawFirm.userId] || {};
          return (
            <div
              key={lawFirm.id}
              className="contentItem"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={lawFirm.profilePicture || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <h3 className="name label detail">Joined At:</h3>
                <h3 className="detail">
                  {lawFirm.createdAt
                    ? new Date(lawFirm.createdAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : lawFirm.updatedAt
                    ? new Date(lawFirm.updatedAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : "Timestamp not available"}
                </h3>
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{lawFirm.firmName}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">City:</p>
                <p className="detail">{lawFirm.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Enrollment ID:</p>
                <p className="detail">{lawFirm.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Experience:</p>
                <p className="detail">{lawFirm.totalExperience}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Service Fee:</p>
                <p className="detail">
                  ₹ {lawFirm.serviceFee} 
                </p>
              </div>
              <div className="buttonContainer">
              <button
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up
                    handleEdit(lawFirm); // Calls handleEdit function
                  }}
                  style={{ marginTop: 10 }}
                >
                  Edit
                </button>
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent
                    handleDelete(lawFirm);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this law firm?`}
        />
      )}
      {detailsModalState.show && (
        <DetailsModal
        show={detailsModalState.show}
        onClose={closeDetailsModal}
        lawFirm={detailsModalState.lawFirm}
        userDetail={userDetails[detailsModalState.lawFirm.userId] || {}}
        onSave={handleSave}
      />
      )}
    </>
  );
};

export default LawFirmsScreen;
