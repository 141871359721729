import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import AdminPanel from "./AdminPanel";
import BlogsScreen from "./BlogScreen";
import VideosScreen from "./VideosScreen";
import LawyersScreen from "./LawyersScreen";
import AuditorsScreen from "./AuditorsScreen";
import LawFirmsScreen from "./LawFirmsScreen";
import AuditFirmsScreen from "./AuditFirmsScreen";
import PrivateDetectivesScreen from "./PrivateDetectivesScreen";
import EventsScreen from "./EventsScreen";
import OpportunitiesScreen from "./OpportunitiesScreen";
import SideMenu from "./SideMenu";
import "./App.css";
import NewsScreen from "./NewsScreen";
import NewsUpload from "./NewsUpload";
import LoginPage from "./LoginPage";
import BannerUpload from "./BannerUpload";
import Banners from "./Banners";
import EventsStudentDetails from "./EventsStudentDetails";
import OpportunityInternDetails from "./OpportunityInternDetails"; // Import the new component
import Payments from "./Payments";
import AddServices from "./AddServices";
import UsersScreen from "./UsersScreen";
import LexiAiInstructions from "./LexiAiInstructions";
import ScholarshipUpload from "./ScholarshipUpload";
import Dataset from "./Dataset";
import OfficialLinks from "./OfficialLinks";
import ConsultantsScreen from "./ConsultantsScreen";
import TransactionHistory from "./TransactionHistory";
import DeletionRequests from "./DeletionRequests";
import WithdrawalRequests from "./WithdrawalRequests";
import Enrollments from "./Enrollments";
import Uploads from "./Uploads";
import EventsAndOpportunity from "./Events&Opportunity";
import HomeBanners from "./HomeBanners";
import TransactionsAndRequests from "./Transactions&Requests";
import LexshieldScreen from "./LexshieldScreen";
import NotificationUser from "./NotificationUser";
import VideoUpload from "./MuxVideoUpload";
import FAQsAdmin from './FAQsAdmin'; // Adjust the path if necessary.

const firebaseConfig = {
  apiKey: "AIzaSyAnNZFV6k-9ZpokQR_d5syekQU9JgHQEa8",
  authDomain: "lexdo-7b0b1.firebaseapp.com",
  projectId: "lexdo-7b0b1",
  storageBucket: "lexdo-7b0b1.appspot.com",
  messagingSenderId: "323278730681",
  appId: "1:323278730681:web:82a5e0300cea57b897f268",
  measurementId: "G-5VQFBFWKDZ",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function App() {
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedTab") || "Pending Approvals"
  );
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("loggedIn") === "true");
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin") === "true");

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        localStorage.setItem("loggedIn", "true");
      } else {
        setLoggedIn(false);
        setIsAdmin(false);
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("isAdmin");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  const handleSignOut = () => {
    firebase.auth().signOut()
      .then(() => {
        setLoggedIn(false);
        setIsAdmin(false);
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("isAdmin");
      })
      .catch((error) => console.error("Sign out error:", error));
  };

  const renderContent = () => {
    if (!loggedIn) {
      return <LoginPage setLoggedIn={setLoggedIn} setIsAdmin={setIsAdmin} />;
    }

    if (!isAdmin) {
      return <div>You do not have access to this page.</div>;
    }

    switch (selectedTab) {
      case "Pending Approvals":
        return <AdminPanel selectedTab={selectedTab} />;
      case "Blogs":
        return <BlogsScreen />;
        case "FAQsAdmin":
          return <FAQsAdmin />;
        
      case "Reels":
        return <VideosScreen />;
      case "MyLawyer":
        return <LawyersScreen />;
      case "MyAuditor":
        return <AuditorsScreen />;
      case "LawFirm":
        return <LawFirmsScreen />;
      case "AuditFirm":
        return <AuditFirmsScreen />;
      case "Consultants":
        return <ConsultantsScreen />;
      case "PrivateDetective":
        return <PrivateDetectivesScreen />;
      case "Events":
        return <EventsScreen />;
      case "Events Student Details": // Added new case
        return <EventsStudentDetails />;
      case "Opportunity":
        return <OpportunitiesScreen />;
      case "Opportunity Intern Details": // Added new case
        return <OpportunityInternDetails />;
      case "News":
        return <NewsScreen />;
      case "News Upload":
        return <NewsUpload />;
      case "Banner Upload":
        return <BannerUpload />;
      case "Banners":
        return <Banners />;
      case "Payments":
        return <Payments />;
      case "Add Services":
          return <AddServices />;
      case "Users":
          return <UsersScreen />;
      case "Add LexiAi Instructions":
          return <LexiAiInstructions />;
      case "Scholarship Upload":
          return <ScholarshipUpload />;
      case "Dataset":
          return <Dataset />;
      case "Official Sites":
          return <OfficialLinks />;
      case "Transactions History":
          return <TransactionHistory />;
      case "Deletion Requests":
          return <DeletionRequests />;
      case "Withdrawal Requests":
          return <WithdrawalRequests />;
      case "Enrollments":
          return <Enrollments />;
      case "Uploads":
          return <Uploads />;
      case "Events&Opportunity":
          return <EventsAndOpportunity />;
      case "HomeBanners":
          return <HomeBanners />;
      case "Transactions&Requests":
          return <TransactionsAndRequests />;
      case "Lexshield":
          return <LexshieldScreen />;
      case "Notification User":
          return <NotificationUser />;
      case "Mux Video Upload":
          return <VideoUpload />;

      default:
        return <AdminPanel selectedTab="Pending Approvals" />;
    }
  };

  return (
    <div className="App">
      <div className="sideMenuContainer">
        <SideMenu
          onSelectTab={setSelectedTab}
          handleSignOut={handleSignOut}
          selectedTab={selectedTab}
        />
      </div>
      <div className="mainContainer">{renderContent()}</div>
    </div>
  );
}

export default App;
