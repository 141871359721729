import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "./ContentPage.css";


const BannerUpload = () => {
  const [page, setPage] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let imageUrl = "";
    try {
      if (image) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`banners/${image.name}`);
        await fileRef.put(image);
        imageUrl = await fileRef.getDownloadURL();
      }

      await firebase.firestore().collection("bannerImages").add({
        page,
        imageUrl,
      });

      setLoading(false);
      setMessage("Banner uploaded successfully!");
      setShowPopup(true);
      clearForm();
    } catch (error) {
      setLoading(false);
      setMessage("Banner not uploaded. Please try again.");
      setShowPopup(true);
    }
  };

  const clearForm = () => {
    setPage("");
    setImage(null);
    setImagePreview(null);
  };

  const closePopup = () => {
    setShowPopup(false);
    setMessage("");
  };

  return (
    <>
      {/* <div className="headingContainer">
        <p className="heading">Upload Banner</p>
      </div> */}
      <div className="uploadContainer">
        <form onSubmit={handleSubmit} className="uploadForm">
          <label style={{ marginBottom: 10 }}>Page</label>
          <select
            style={{
              height: "35px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "0 10px",
            }}
            value={page}
            onChange={(e) => setPage(e.target.value)}
            required
          >
            <option value="" disabled>
              Select a page
            </option>
            <option value="JoinLawyer">Lawyer Form</option>
            <option value="JoinAuditor">Auditor Form</option>
            <option value="JoinLawFirm">Law Firm Form</option>
            <option value="JoinConsultant">Consultants Form</option>
            <option value="JoinPrivateDetective">Private Detective Form</option>
            <option value="Kaappaan">Lex Shield</option>
            <option value="LexiAI">Lexi AI</option>
            <option value="Service">Services</option>
            <option value="Tools">Tools</option>
            <option value="Blogs">Blogs</option>
            <option value="News">News & Feed</option>
            <option value="Events">Events</option>
            <option value="Opportunity">Opportunity</option>
            <option value="JoinAs">Join As</option>
            <option value="MyLawyer">My Lawyer</option>
            <option value="MyAuditor">My Auditor</option>
            <option value="LawFirm">Law Firms</option>
            <option value="AuditFirm">Audit Firms</option>
            <option value="PrivateDetective">Private Detective</option>
          </select>
          <label style={{ marginTop: 30 }}>Image</label>
          <div
            className="imageUploadContainer"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            <input
              id="imageInput"
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <button type="button">{image ? image.name : "Upload Image"}</button>
          </div>
          {imagePreview && (
            <div className="imagePreviewContainer">
              <img src={imagePreview} alt="Selected" className="imagePreview" />
            </div>
          )}
          <button type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </button>
        </form>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popupContent">
            <p>{message}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerUpload;
