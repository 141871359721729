import React, { useState } from "react";
import "./Payments.css"; // For consistent styling
import EventsScreen from "./EventsScreen";
import OpportunitiesScreen from "./OpportunitiesScreen";
import EventsStudentDetails from "./EventsStudentDetails";
import OpportunityInternDetails from "./OpportunityInternDetails";

const EventsAndOpportunity = () => {
  const [activeTab, setActiveTab] = useState("Events");

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "Events":
        return <EventsScreen />;
      case "Opportunity":
        return <OpportunitiesScreen />;
      case "Events Students Details":
        return <EventsStudentDetails />;
      case "Opportunity Intern Details":
        return <OpportunityInternDetails />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="sub-tabs">
        <div
          className={`sub-tab ${activeTab === "Events" ? "active" : ""}`}
          onClick={() => setActiveTab("Events")}
        >
          Events
        </div>
        <div
          className={`sub-tab ${activeTab === "Opportunity" ? "active" : ""}`}
          onClick={() => setActiveTab("Opportunity")}
        >
          Opportunity
        </div>
        <div
          className={`sub-tab ${activeTab === "Events Students Details" ? "active" : ""}`}
          onClick={() => setActiveTab("Events Students Details")}
        >
          Events Students Details
        </div>
        <div
          className={`sub-tab ${activeTab === "Opportunity Intern Details" ? "active" : ""}`}
          onClick={() => setActiveTab("Opportunity Intern Details")}
        >
          Opportunity Intern Details
        </div>
      </div>

      <div className="sub-tab-content">{renderActiveTabContent()}</div>
    </div>
  );
};

export default EventsAndOpportunity;
