import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const DetailsModal = ({ show, onClose, auditFirm, userDetail, onSave }) => {
  const [editAuditFirm, setEditAuditFirm] = useState(auditFirm);

  useEffect(() => {
    setEditAuditFirm(auditFirm);
  }, [auditFirm]);

  const handleFieldChange = (field, value) => {
    setEditAuditFirm((prev) => ({ ...prev, [field]: value }));
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-payment">
      <div className="modal-content-payment">
        <h2>Edit Audit Firm Details</h2>
        <div className="detail-container">
          <p className="label">Name:</p>
          <input
            className="detail"
            value={editAuditFirm.firmName}
            onChange={(e) => handleFieldChange("firmName", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">City:</p>
          <input
            className="detail"
            value={editAuditFirm.residentialAddress.city}
            onChange={(e) =>
              handleFieldChange("residentialAddress", {
                ...editAuditFirm.residentialAddress,
                city: e.target.value,
              })
            }
          />
        </div>
        <div className="detail-container">
          <p className="label">Qualification ID:</p>
          <input
            className="detail"
            value={editAuditFirm.qualificationId}
            onChange={(e) => handleFieldChange("qualificationId", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Experience:</p>
          <input
            className="detail"
            value={editAuditFirm.totalExperience}
            onChange={(e) => handleFieldChange("totalExperience", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Service Fee:</p>
          <input
            className="detail"
            value={editAuditFirm.serviceFee}
            onChange={(e) => handleFieldChange("serviceFee", e.target.value)}
          />
        </div>
        <div className="modal-actions">
          <button onClick={() => onSave(editAuditFirm)} className="modal-button">
            Save
          </button>
          <button onClick={onClose} className="modal-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};


const AuditFirmsScreen = () => {
  const [approvedAuditFirms, setApprovedAuditFirms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    auditFirm: null,
  });
  const [detailsModalState, setDetailsModalState] = useState({
    show: false,
    auditFirm: null,
    isEditMode: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAuditFirms, setFilteredAuditFirms] = useState([]);

  useEffect(() => {
    const fetchApprovedAuditFirms = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedAuditFirms")
          .orderBy("createdAt", "desc")
          .get();
        const auditFirms = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = auditFirms.map((auditFirm) => auditFirm.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedAuditFirms(auditFirms);
        setFilteredAuditFirms(auditFirms);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved audit firms: ", error);
        setLoading(false);
      }
    };

    fetchApprovedAuditFirms();
  }, []);

  useEffect(() => {
    setFilteredAuditFirms(
      approvedAuditFirms.filter((auditFirm) => {
        const userDetail = userDetails[auditFirm.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditFirm.residentialAddress.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditFirm.qualificationId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditFirm.totalExperience?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditFirm.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditFirm.expertiseAreas.some((area) =>
            area.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
    );
  }, [searchTerm, approvedAuditFirms, userDetails]);

  const handleDelete = (auditFirm) => {
    setModalState({ show: true, auditFirm });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedAuditFirms")
        .doc(modalState.auditFirm.id)
        .delete();
      setApprovedAuditFirms((prev) =>
        prev.filter((item) => item.id !== modalState.auditFirm.id)
      );
      setFilteredAuditFirms((prev) =>
        prev.filter((item) => item.id !== modalState.auditFirm.id)
      );
      setModalState({ show: false, auditFirm: null });
    } catch (error) {
      console.error("Error deleting audit firm: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, auditFirm: null });
  };

  const handleItemClick = (auditFirm) => {
    setDetailsModalState({ show: true, auditFirm });
  };

  const handleEdit = (auditFirm) => {
    setDetailsModalState({ show: true, auditFirm, isEditMode: true });
  };

  const handleSave = async (updatedAuditFirm) => {
    try {
      await firebase.firestore().collection("approvedAuditFirms").doc(updatedAuditFirm.id).update(updatedAuditFirm);
      setApprovedAuditFirms((prev) =>
        prev.map((firm) => (firm.id === updatedAuditFirm.id ? updatedAuditFirm : firm))
      );
      setDetailsModalState({ show: false, auditFirm: null, isEditMode: false });
      window.alert("Audit Firm details updated successfully.");
    } catch (error) {
      console.error("Error updating audit firm details: ", error);
      window.alert("There was an error updating the details. Please try again.");
    }
  };

  const closeDetailsModal = () => {
    setDetailsModalState({ show: false, auditFirm: null, isEditMode: false });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Audit Firms: {filteredAuditFirms.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Audit Firms..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredAuditFirms.map((auditFirm) => {
          const userDetail = userDetails[auditFirm.userId] || {};
          return (
            <div
              key={auditFirm.id}
              className="contentItem"
              onClick={() => handleItemClick(auditFirm)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={auditFirm.profilePicture || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <h3 className="name label detail">Joined At:</h3>
                <h3 className="detail">
                  {auditFirm.createdAt
                    ? new Date(auditFirm.createdAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : auditFirm.updatedAt
                    ? new Date(auditFirm.updatedAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : "Timestamp not available"}
                </h3>
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{auditFirm.firmName}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">City:</p>
                <p className="detail">{auditFirm.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Qualification ID:</p>
                <p className="detail">{auditFirm.qualificationId}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Experience:</p>
                <p className="detail">{auditFirm.totalExperience}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Service Fee:</p>
                <p className="detail">
                  ₹ {auditFirm.serviceFee} 
                </p>
              </div>
              <div className="buttonContainer">
              <button
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from bubbling up
                    handleEdit(auditFirm); // Calls handleEdit function
                  }}
                  style={{ marginTop: 10 }}
                >
                  Edit
                </button>
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent
                    handleDelete(auditFirm);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this audit firm?`}
        />
      )}
      {detailsModalState.show && (
        <DetailsModal
        show={detailsModalState.show}
        onClose={closeDetailsModal}
        auditFirm={detailsModalState.auditFirm}
        userDetail={userDetails[detailsModalState.auditFirm.userId] || {}}
        onSave={handleSave}
      />
      )}
    </>
  );
};

export default AuditFirmsScreen;
