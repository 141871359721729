import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "./Payments.css";

// Firebase configuration should already be initialized in your project
const firestore = firebase.firestore();

const toolsList = [
  "Merge PDF",
  "Split PDF",
  "Compress PDF",
  "PDF OCR",
  "PDF to JPG",
  "Image to PDF",
  "Unlock PDF",
  "Watermark PDF",
  "Office to PDF",
  "Repair PDF",
  "Protect PDF",
  "PDF to PDF/A",
  "Validate PDF/A",
  "Extract PDF Text",
  "Resize Image",
  "Crop Image",
  "Compress Image",
  "Upscale Image",
  "Remove Background",
  "Convert Image",
  "Watermark Image",
  "Page Number PDF",
  "Translate PDF",
  "Speech to Text Live",
  "Word to PDF",
  "Forms",
  "Speech To Translation",
  "PDF to Word",
];

const Payments = () => {
  const [selectedSubTab, setSelectedSubTab] = useState("Events");
  const [opportunityPrice, setOpportunityPrice] = useState('');
  const [eventPrice, setEventPrice] = useState('');
  const [lexiAiPrice, setLexiAiPrice] = useState('');
  const [lexiAiDuration, setLexiAiDuration] = useState('');
  const [lexiAiPricing, setLexiAiPricing] = useState([]);
  const [toolsPricing, setToolsPricing] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isLexiAiModalOpen, setIsLexiAiModalOpen] = useState(false);
  const [isToolModalOpen, setIsToolModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editToolIndex, setEditToolIndex] = useState(-1);
  const [selectedTool, setSelectedTool] = useState('');
  const [toolPrice, setToolPrice] = useState('');
  const [addressPrice, setAddressPrice] = useState('');
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);


  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const opportunityPriceDoc = await firestore.collection("opportunityPrice").doc("price").get();
        if (opportunityPriceDoc.exists) setOpportunityPrice(opportunityPriceDoc.data().amount);

        const eventPriceDoc = await firestore.collection("eventPrice").doc("price").get();
        if (eventPriceDoc.exists) setEventPrice(eventPriceDoc.data().amount);

        const lexiAiDoc = await firestore.collection("lexiAi").doc("pricing").get();
        if (lexiAiDoc.exists) setLexiAiPricing(lexiAiDoc.data().prices || []);

        const addressPriceDoc = await firestore.collection('addressPrice').doc('price').get();
        if (addressPriceDoc.exists) setAddressPrice(addressPriceDoc.data().amount); // Fetch and set the amount

        const toolsPriceDoc = await firestore.collection("toolsPrice").get();
        const toolsPrices = toolsPriceDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setToolsPricing(toolsPrices);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPrices();
  }, []);

  // Save Opportunity Price as a number
  const handleSaveOpportunityPrice = async () => {
    try {
      await firestore.collection("opportunityPrice").doc("price").set({ amount: parseFloat(opportunityPrice) });
      setIsModalOpen(false);
      window.alert('Opportunity price updated successfully.');
    } catch (error) {
      console.error("Error updating opportunity price: ", error);
    }
  };

  // Save Event Price as a number
  const handleSaveEventPrice = async () => {
    try {
      await firestore.collection("eventPrice").doc("price").set({ amount: parseFloat(eventPrice) });
      setIsEventModalOpen(false);
      window.alert('Event price updated successfully.');
    } catch (error) {
      console.error("Error updating event price: ", error);
    }
  };

  // Save Lexi AI Price as a number
  const handleSaveLexiAiPrice = async () => {
    try {
      const updatedPricing = [...lexiAiPricing];
      if (editIndex >= 0) {
        // Editing an existing price
        updatedPricing[editIndex] = { price: parseFloat(lexiAiPrice), duration: lexiAiDuration };
        setEditIndex(-1);
      } else {
        // Adding a new price
        updatedPricing.push({ price: parseFloat(lexiAiPrice), duration: lexiAiDuration });
      }

      await firestore.collection("lexiAi").doc("pricing").set({
        prices: updatedPricing
      }, { merge: true });

      setLexiAiPricing(updatedPricing);
      setLexiAiPrice('');
      setLexiAiDuration('');
      setIsLexiAiModalOpen(false);
      window.alert('Lexi AI pricing updated successfully.');
    } catch (error) {
      console.error("Error updating Lexi AI pricing: ", error);
    }
  };

  // Save Tool Price as a number (with "All" option functionality)
  const handleSaveToolPrice = async () => {
    try {
      let updatedPricing = [...toolsPricing];

      if (selectedTool === "All") {
        // If "All" is selected, loop through all tools
        const newPrices = toolsList.map((tool) => ({ tool, price: parseFloat(toolPrice) }));
        const batch = firestore.batch();

        // Add new prices for each tool in the toolsList
        newPrices.forEach((toolItem) => {
          const docRef = firestore.collection("toolsPrice").doc(); // Create a new document
          batch.set(docRef, toolItem); // Add operation to the batch
        });

        await batch.commit(); // Commit all operations in the batch

        updatedPricing = [...updatedPricing, ...newPrices]; // Update the local state with the new prices
      } else if (editToolIndex >= 0) {
        // Editing an existing tool price
        updatedPricing[editToolIndex] = { tool: selectedTool, price: parseFloat(toolPrice) };
        const toolDocId = toolsPricing[editToolIndex].id;
        await firestore.collection("toolsPrice").doc(toolDocId).set({ tool: selectedTool, price: parseFloat(toolPrice) });
        setEditToolIndex(-1);
      } else {
        // Adding a new tool price for a specific tool
        const newTool = { tool: selectedTool, price: parseFloat(toolPrice) };
        const docRef = await firestore.collection("toolsPrice").add(newTool);
        updatedPricing.push({ id: docRef.id, ...newTool });
      }

      setToolsPricing(updatedPricing);
      setToolPrice("");
      setSelectedTool("");
      setIsToolModalOpen(false);
      window.alert("Tool price updated successfully.");
    } catch (error) {
      console.error("Error updating tool price: ", error);
    }
  };

  const handleEditLexiAiPrice = (index) => {
    setEditIndex(index);
    setLexiAiPrice(lexiAiPricing[index].price);
    setLexiAiDuration(lexiAiPricing[index].duration);
    setIsLexiAiModalOpen(true);
  };

  const handleEditToolPrice = (index) => {
    setEditToolIndex(index);
    setSelectedTool(toolsPricing[index].tool);
    setToolPrice(toolsPricing[index].price);
    setIsToolModalOpen(true);
  };

  const handleDeleteLexiAiPrice = async (index) => {
    try {
      const updatedPricing = lexiAiPricing.filter((_, i) => i !== index);
      await firestore.collection("lexiAi").doc("pricing").set({
        prices: updatedPricing
      }, { merge: true });
      setLexiAiPricing(updatedPricing);
      window.alert('Lexi AI pricing deleted successfully.');
    } catch (error) {
      console.error("Error deleting Lexi AI pricing: ", error);
    }
  };

  const handleDeleteToolPrice = async (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this tool price?");
    if (confirmDelete) {
      try {
        const toolDocId = toolsPricing[index].id;
        await firestore.collection("toolsPrice").doc(toolDocId).delete();
        const updatedPricing = toolsPricing.filter((_, i) => i !== index);
        setToolsPricing(updatedPricing);
        window.alert("Tool price deleted successfully.");
      } catch (error) {
        console.error("Error deleting tool price: ", error);
      }
    }
  };

  const handleDeleteOpportunityPrice = async () => {
    try {
      await firestore.collection("opportunityPrice").doc("price").delete();
      setOpportunityPrice('');
      window.alert('Opportunity price deleted successfully.');
    } catch (error) {
      console.error("Error deleting opportunity price: ", error);
    }
  };

  const handleDeleteEventPrice = async () => {
    try {
      await firestore.collection("eventPrice").doc("price").delete();
      setEventPrice('');
      window.alert('Event price deleted successfully.');
    } catch (error) {
      console.error("Error deleting event price: ", error);
    }
  };

  // Save Address Price as a number
const handleSaveAddressPrice = async () => {
  try {
    await firestore.collection("addressPrice").doc("price").set({ amount: parseFloat(addressPrice) });
    setIsAddressModalOpen(false);
    window.alert('Address view price updated successfully.');
  } catch (error) {
    console.error("Error updating address view price: ", error);
  }
};

// Delete Address Price
const handleDeleteAddressPrice = async () => {
  try {
    await firestore.collection("addressPrice").doc("price").delete();
    setAddressPrice('');
    window.alert('Address view price deleted successfully.');
  } catch (error) {
    console.error("Error deleting address view price: ", error);
  }
};


  return (
    <div className="payments-container">
      <div className="sub-tabs">
        {["Events", "Opportunity", "Lexi AI", "Tools", "Address View Price"].map(tab => (
          <div
            key={tab}
            className={`sub-tab ${selectedSubTab === tab ? "active" : ""}`}
            onClick={() => setSelectedSubTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className="sub-tab-content">
        {selectedSubTab === "Opportunity" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsModalOpen(true)}>Set Opportunity Price</button>
            </div>
            {opportunityPrice && (
              <div className="opportunity-price">
                <span>Current Opportunity Price: ₹{opportunityPrice}</span>
                <div>
                  <button className="button button-edit" onClick={() => setIsModalOpen(true)}>Edit</button>
                  <button className="button button-delete" onClick={handleDeleteOpportunityPrice}>Delete</button>
                </div>
              </div>
            )}
            {isModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Opportunity Price</h3>
                  <input
                    type="text"
                    value={opportunityPrice}
                    onChange={(e) => setOpportunityPrice(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveOpportunityPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedSubTab === "Events" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsEventModalOpen(true)}>Set Event Price</button>
            </div>
            {eventPrice && (
              <div className="opportunity-price">
                <span>Current Event Price: ₹{eventPrice}</span>
                <div>
                  <button className="button button-edit" onClick={() => setIsEventModalOpen(true)}>Edit</button>
                  <button className="button button-delete" onClick={handleDeleteEventPrice}>Delete</button>
                </div>
              </div>
            )}
            {isEventModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Event Price</h3>
                  <input
                    type="text"
                    value={eventPrice}
                    onChange={(e) => setEventPrice(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveEventPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsEventModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedSubTab === "Lexi AI" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsLexiAiModalOpen(true)}>Set Lexi AI Price</button>
            </div>
            {lexiAiPricing.map((item, index) => (
              <div key={index} className="opportunity-price">
                <span>Price: {item.price}, Duration: {item.duration}</span>
                <div>
                  <button className="button button-edit" onClick={() => handleEditLexiAiPrice(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteLexiAiPrice(index)}>Delete</button>
                </div>
              </div>
            ))}
            {isLexiAiModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Lexi AI Price and Duration</h3>
                  <input
                    type="text"
                    placeholder="Price in coins"
                    value={lexiAiPrice}
                    onChange={(e) => setLexiAiPrice(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Duration (e.g., 3 Months)"
                    value={lexiAiDuration}
                    onChange={(e) => setLexiAiDuration(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveLexiAiPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsLexiAiModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedSubTab === "Tools" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsToolModalOpen(true)}>Add Tool Price</button>
            </div>
            {toolsPricing.map((item, index) => (
              <div key={index} className="opportunity-price">
                <span>Tool: {item.tool}, Price: ₹{item.price}</span>
                <div>
                  <button className="button button-edit" onClick={() => handleEditToolPrice(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteToolPrice(index)}>Delete</button>
                </div>
              </div>
            ))}
            {isToolModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>{editToolIndex >= 0 ? "Edit Tool Price" : "Add Tool Price"}</h3>
                  <select
                    disabled={editToolIndex >= 0}
                    value={selectedTool}
                    onChange={(e) => setSelectedTool(e.target.value)}
                  >
                    <option value="">Select Tool</option>
                    <option value="All">All</option> {/* Add option to select all tools */}
                    {toolsList.map((tool) => (
                      <option key={tool} value={tool}>{tool}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    placeholder="Price in ₹"
                    value={toolPrice}
                    onChange={(e) => setToolPrice(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveToolPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsToolModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

{selectedSubTab === "Address View Price" && (
  <div>
    <div>
      <button className="button" onClick={() => setIsAddressModalOpen(true)}>Set Address View Price</button>
    </div>
    {addressPrice && (
      <div className="opportunity-price">
        <span>Current Address View Price: ₹{addressPrice}</span>
        <div>
          <button className="button button-edit" onClick={() => setIsAddressModalOpen(true)}>Edit</button>
          <button className="button button-delete" onClick={handleDeleteAddressPrice}>Delete</button>
        </div>
      </div>
    )}
    {isAddressModalOpen && (
      <div className="modal-payment">
        <div className="modal-content-payment">
          <h3>Set Address View Price</h3>
          <input
            type="text"
            value={addressPrice}
            onChange={(e) => setAddressPrice(e.target.value)}
          />
          <div className="modal-buttons">
            <button className="button button-save" onClick={handleSaveAddressPrice}>Save</button>
            <button className="button button-cancel" onClick={() => setIsAddressModalOpen(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}
  </div>
)}


        
      </div>
    </div>
  );
};

export default Payments;
