import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const BlogsScreen = () => {
  const [approvedBlogs, setApprovedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({
    show: false,
    blog: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  useEffect(() => {
    const fetchApprovedBlogs = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedBlogs")
          .orderBy("createdAt", "desc")
          .get();
        const blogs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setApprovedBlogs(blogs);
        setFilteredBlogs(blogs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved blogs: ", error);
        setLoading(false);
      }
    };

    fetchApprovedBlogs();
  }, []);

  useEffect(() => {
    setFilteredBlogs(
      approvedBlogs.filter((blog) =>
        blog.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.author?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, approvedBlogs]);

  const handleDelete = (blog) => {
    setModalState({ show: true, blog });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedBlogs")
        .doc(modalState.blog.id)
        .delete();
      setApprovedBlogs((prev) =>
        prev.filter((item) => item.id !== modalState.blog.id)
      );
      setFilteredBlogs((prev) =>
        prev.filter((item) => item.id !== modalState.blog.id)
      );
      setModalState({ show: false, blog: null });
    } catch (error) {
      console.error("Error deleting blog: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, blog: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Blogs: {filteredBlogs.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Blogs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainerBlogs">
        {filteredBlogs.map((blog) => (
          <div key={blog.id} className="contentItemBlogs">
            <h3 className="title">{blog.title}</h3>
            <p>{blog.content}</p>
            <div
              className="userInfo"
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div>
                <img
                  src={blog.imageUrl}
                  alt={blog.author}
                  className="profileImageBlogs"
                />
              </div>
              <div>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Author: </strong>
                  {blog.author}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Date: </strong>
                  {blog.date}
                </p>
              </div>
            </div>
            <div className="buttonContainer">
              <button
                className="delete-icon"
                onClick={() => handleDelete(blog)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this blog?`}
        />
      )}
    </>
  );
};

export default BlogsScreen;
