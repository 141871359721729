import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faCalendarCheck,
  faCoffee,
  faCalendar,
  faUser,
  faFileAlt,
  faSignOutAlt,
  faMoneyBillAlt,
  faPlusSquare
} from "@fortawesome/free-solid-svg-icons";
import "./SideMenu.css";

const SideMenu = ({ onSelectTab, handleSignOut, selectedTab }) => {
  const tabs = [
    { name: "Pending Approvals", icon: faTachometerAlt },
    { name: "Users", icon: faUser },
    { name: "Notification User", icon: faPlusSquare },
    { name: "Enrollments", icon: faPlusSquare },
    { name: "Uploads", icon: faPlusSquare },
    { name: "Events&Opportunity", icon: faPlusSquare },
    { name: "HomeBanners", icon: faCalendar },
    { name: "Transactions&Requests", icon: faPlusSquare },
    // { name: "MyLawyer", icon: faUser },
    // { name: "MyAuditor", icon: faUser },
    // { name: "LawFirm", icon: faFileAlt },
    // { name: "AuditFirm", icon: faFileAlt },
    // { name: "Consultants", icon: faFileAlt },
    // { name: "PrivateDetective", icon: faUser },
    // { name: "Lexshield", icon: faUser },
    // { name: "Blogs", icon: faCalendarCheck },
    // { name: "News", icon: faCalendar },
    // { name: "Reels", icon: faCoffee },
    // { name: "Events", icon: faCalendar },
    // { name: "Events Student Details", icon: faCalendar },
    // { name: "Opportunity", icon: faCalendar },
    // { name: "Opportunity Intern Details", icon: faCalendar },
    // { name: "Banners", icon: faCalendar },
    // { name: "News Upload", icon: faCalendar },
    // { name: "Banner Upload", icon: faCalendar },
    { name: "Payments", icon: faMoneyBillAlt },
    { name: "Add Services", icon: faPlusSquare },
    // { name: "Add LexiAi Instructions", icon: faPlusSquare },
    { name: "Scholarship Upload", icon: faPlusSquare },
    // { name: "Dataset", icon: faPlusSquare },
    { name: "Official Sites", icon: faPlusSquare },
    { name: "Mux Video Upload", icon: faPlusSquare },
    { name: "FAQsAdmin", icon: faPlusSquare },

    // { name: "Transactions History", icon: faPlusSquare },
    // { name: "Deletion Requests", icon: faPlusSquare },
    // { name: "Withdrawal Requests", icon: faPlusSquare },

  ];

  return (
    <div className="side-menu">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={`menu-item ${selectedTab === tab.name ? "active" : ""}`}
          onClick={() => onSelectTab(tab.name)}
        >
          <FontAwesomeIcon icon={tab.icon} className="menu-icon" />
          <span>{tab.name}</span>
        </div>
      ))}
      <div className="menu-item sign-out" onClick={handleSignOut}>
        <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" />
        <span>Sign Out</span>
      </div>
    </div>
  );
};

export default SideMenu;
